<template>
  <el-card>
    <div slot="header">
      <span>供应商管理</span>
    </div>
    <!-- 供应商shaixu -->
    <el-row :gutter="20">
      <el-col :span="6">
        <el-input
          v-model="queryFilter.keyWorlds"
          placeholder="请输入供应商相关信息"
          clearable
      /></el-col>
      <el-button type="primary" @click="flashTableData">查询供应商</el-button>
      <el-button
        type="primary"
        style="float: right"
        @click="addSupplierDialogVisabled = true"
        >新增供应商</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table :data="supplierData" border :max-height="600">
        <el-table-column label="#" width="60" type="index"></el-table-column>
        <el-table-column label="供应商名称" prop="name"></el-table-column>
        <el-table-column label="联系人" prop="contact"></el-table-column>
        <el-table-column label="联系电话" prop="phone"></el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="编辑">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="openEditSupplierDialog(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="deletSupplier(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        style="margin-top: 20px; text-align: right"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryFilter.pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryFilter.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="queryFilter.total"
      >
      </el-pagination>
    </el-row>

    <!-- 新增供应商 -->
    <el-dialog
      title="新增供应商"
      :visible.sync="addSupplierDialogVisabled"
      @close="addSupplierDialogClose"
    >
      <el-form
        ref="addSupplierFormRef"
        :model="addSupplierForm"
        :rules="addSupplierFormRules"
        label-width="100"
      >
        <el-form-item prop="name" label="供应商名称">
          <el-input
            v-model="addSupplierForm.name"
            autocomplete="off"
            placeholder="供应商名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="contact" label="联系人">
          <el-input
            v-model="addSupplierForm.contact"
            autocomplete="off"
            placeholder="联系人"
          ></el-input>
        </el-form-item>
        <el-form-item prop="price" label="联系电话">
          <el-input
            v-model="addSupplierForm.phone"
            autocomplete="off"
            placeholder="联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input
            v-model="addSupplierForm.address"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="addSupplierForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addSupplierDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="addSupplierDialogConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 编辑供应商 -->
    <el-dialog title="编辑供应商" :visible.sync="eidtSupplierDialogVisabled">
      <el-form
        ref="editSupplierFormRef"
        :model="editSupplierForm"
        :rules="eidtSupplierFormRules"
        label-width="100"
      >
        <el-form-item prop="name" label="供应商名称">
          <el-input
            v-model="editSupplierForm.name"
            autocomplete="off"
            placeholder="供应商名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="contact" label="联系人">
          <el-input
            v-model="editSupplierForm.contact"
            autocomplete="off"
            placeholder="联系人"
          ></el-input>
        </el-form-item>
        <el-form-item prop="price" label="联系电话">
          <el-input
            v-model="editSupplierForm.phone"
            autocomplete="off"
            placeholder="联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input
            v-model="editSupplierForm.address"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="editSupplierForm.remark"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="eidtSupplierDialogVisabled = false">取 消</el-button>
        <el-button type="primary" @click="editSupplierDialogConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import clip from '@/utils/clipboard'
import {
  getSuppliers,
  getSupplier,
  addSupplier,
  updateSupplier,
  deleteSupplier,
} from '@/api/supplier'
export default {
  data() {
    return {
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        status: null,
      },
      // 供应商数据
      supplierData: [],
      // 新增供应商对话框显示隐藏
      addSupplierDialogVisabled: false,
      // 新增供应商表单
      addSupplierForm: {
        name: '',
        contact: '',
        phone: '',
        address: '',
        remark: '',
      },
      addSupplierFormRules: {
        name: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' },
        ],
      },

      eidtSupplierDialogVisabled: false,
      // 新增供应商表单
      editSupplierForm: {
        id: null,
        name: '',
        contact: '',
        phone: '',
        address: '',
        remark: '',
      },
      eidtSupplierFormRules: {
        name: [
          { required: true, message: '请输入供应商名称', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.getSupplierData()
  },
  methods: {
    flashTableData() {
      this.queryFilter.pageIndex = 1
      this.getSupplierData()
    },
    async getSupplierData() {
      let { data } = await getSuppliers(this.queryFilter)
      this.supplierData = data.data
      this.queryFilter.total = data.total
    },
    // 分页大小更改
    handleSizeChange(value) {
      this.queryFilter.pageSize = value
      this.getSupplierData()
    },
    // 当前索引更改
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getSupplierData()
    },
    // 操作粘贴板使用
    handleCopy(text, event) {
      if (text !== '') {
        clip(text, event)
      }
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 重置新增表单
    addSupplierDialogClose() {
      this.resetForm('addSupplierFormRef')
    },
    addSupplierDialogClose() {
      this.$refs.addSupplierFormRef.resetFields()

      this.addSupplierForm = {
        name: '',
        contact: '',
        phone: '',
        address: '',
        remark: '',
      }
    },
    addSupplierDialogConfirm() {
      this.$refs.addSupplierFormRef.validate(async (valid) => {
        if (valid) {
          let { data } = await addSupplier(this.addSupplierForm)
          this.$message.success('新增供应商成功')
          this.getSupplierData()
          this.addSupplierDialogVisabled = false
        }
      })
    },
    openEditSupplierDialog(row) {
      this.editSupplierForm.id = row.id
      this.editSupplierForm.name = row.name
      this.editSupplierForm.contact = row.contact
      this.editSupplierForm.phone = row.phone
      this.editSupplierForm.address = row.address
      this.editSupplierForm.remark = row.remark

      this.eidtSupplierDialogVisabled = true
    },
    editSupplierDialogConfirm() {
      this.$refs.editSupplierFormRef.validate(async (valid) => {
        if (valid) {
          let { data } = await updateSupplier(
            this.editSupplierForm.id,
            this.editSupplierForm
          )
          this.$message.success('更新供应商成功')
          this.getSupplierData()
          this.eidtSupplierDialogVisabled = false
        }
      })
    },
    deletSupplier(row) {
      this.$confirm('此操作将删除当前供应商, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          await deleteSupplier(row.id)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getSupplierData()
        })
        .catch((error) => {
          this.$message({
            type: 'info',
            message: '取消删除',
          })
        })
    },
  },
}
</script>
<style lang="scss" scope></style>
